.texto-normal{
    font-size: 15px;
}

.section-page {
    .section-icons {
        padding: 60px 0;
        .icons-nucleo{
            .nc-icon {
                position: absolute;
                opacity: 0;
                transform: translate3D(0px, 50px, 0px);
                -webkit-transform: translate3D(0px, 50px, 0px);
                -webkit-transition: all 0.6s cubic-bezier(0.34, 2, 0.7, 1) 0s;
                -moz-transition: all 0.6s cubic-bezier(0.34, 2, 0.7, 1) 0s;
                -o-transition: all 0.6s cubic-bezier(0.34, 2, 0.7, 1) 0s;
                -ms-transition: all 0.6s cubic-bezier(0.34, 2, 0.7, 1) 0s;
                transition: all 0.6s cubic-bezier(0.34, 2, 0.7, 1) 0s;
                top: 12%;
                left: 14%;
                font-size: 55px;    
                color:$danger-color;    
            }
        }
    }
} 
.section-page .animated {
    transform: translate3D(0px, 0px, 0px) !important;
    -webkit-transform: translate3D(0px, 0px, 0px) !important;
    opacity: 1 !important;
}
@media(max-width:420px){
    .section-page {
        .title{
            font-size: 1.75rem;
        }
        .description{
            font-size: 0.85rem;
        }
        .section-icons {
            padding: 60px 0;
            .icons-nucleo{
                .nc-icon {
                    top: -18%;
                    left: calc(50% - 25px);
                    font-size: 50px;      
                }
            }
        }
    } 
}